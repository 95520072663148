import { createBrowserRouter } from "react-router-dom";
import HomePage from "../components/normal/HomePage/HomePage";
import MobileHomePage from "../components/mobile/HomePage/HomePage";
import { useState, useEffect } from "react";

function getHomePageElement() {
    const isMobile = checkIsMobile();
    return isMobile ? <MobileHomePage /> : <HomePage />;
}

function checkIsMobile() {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)"); // Adjust the value according to your mobile screen size breakpoint
    return mobileMediaQuery.matches;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: getHomePageElement()
    },
]);

export default router;