import React, { useState, useEffect } from 'react';
import styles from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";
import mainLogo from "../../../assets/images/barebones-logo-white.png";

interface navbarProps {
    homeRef: any;
    featuresRef: any;
    communityRef: any;
    aboutUsRef: any;
}

const Navbar = (props: navbarProps) => {

    

    return (
        <nav className={styles.navbarBody}>
            <div className={styles.homeButton}>
                <img className={styles.homeButtonLogo} src={mainLogo}></img>
            </div>
        </nav>
    );
}

export default Navbar;