import React, { useState, useEffect } from 'react';
import styles from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";
import mainLogo from "../../../assets/images/barebones-logo-white.png";

interface navbarProps {
    homeRef: any;
    featuresRef: any;
    communityRef: any;
    aboutUsRef: any;
}

const Navbar = (props: navbarProps) => {

    const iosAppLink = "http://eepurl.com/hX760z";
    const goToExternalLink = (externalLink: string) => {
        window.location.href = externalLink;
    }

    const goToHomePage = () => {
        const homeRef = props.homeRef;
        homeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const goToFeatures = () => {
        const featuresRef = props.featuresRef;
        featuresRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const goToCommunity = () => {
        const communityRef = props.communityRef;
        communityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const goToAboutUs = () => {
        const aboutUsRef = props.aboutUsRef;
        aboutUsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        // Add scroll listener
        window.addEventListener('scroll', handleScroll);

        // Clean up on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    return (
        <nav className={`${styles.navbarBody} ${scrolled ? styles.scrolled : ''}`}>
            <div className={`${styles.homeButton} ${scrolled ? styles.scrolled : ''}`} onClick={goToHomePage}>
                {/* <p className={styles.homeButtonText}>BAREBONES</p> */}
                <img className={`${styles.homeButtonLogo} ${scrolled ? styles.scrolled : ''}`} src={mainLogo}></img>
            </div>
            <div className={styles.navBarItems}>
                <p className={`${styles.navBarItem} ${scrolled ? styles.scrolled : ''}`} onClick={goToFeatures}>Features</p>
                <p className={`${styles.navBarItem} ${scrolled ? styles.scrolled : ''}`} onClick={goToCommunity}>Community</p>
                <p className={`${styles.navBarItem} ${scrolled ? styles.scrolled : ''}`} onClick={goToAboutUs}>About us</p>
                <div className={`${styles.btnDownload} ${scrolled ? styles.scrolled : ''}`} onClick={() => {
                    goToExternalLink(iosAppLink);
                }}>
                    Stay in the Loop!
                </div>
            </div>
        </nav>
    );
}

export default Navbar;