import React from "react";
import styles from "./HomePage.module.css";
import Navbar from "../Navbar/Navbar";
import f1img from "../../../assets/images/f-1.png";
import f2img from "../../../assets/images/f-2.png";
import f3img from "../../../assets/images/f-3.png";
import f4img from "../../../assets/images/f-4.png";
import tm1img from "../../../assets/images/tm-1.jpg";
import tm2img from "../../../assets/images/tm-2.jpg";
import tm3img from "../../../assets/images/tm-3.jpg";
import tiktokimg from "../../../assets/images/tiktok.png";
import instaimg from "../../../assets/images/instagram.png";
import linkedinimg from "../../../assets/images/linkedin.png";
import creatorScreen from "../../../assets/images/creator-screen.png";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import { useRef } from "react";

const HomePage: React.FC = () => {

    const homeRef = useRef(null);
    const featureRef = useRef(null);
    const communityRef = useRef(null);
    const aboutUsRef = useRef(null);

    const tiktokLink = "https://www.tiktok.com/@barebonesfitness_";
    const instagramLink = "https://www.instagram.com/barebonesfitness_/";
    const linkedinLink = "https://www.linkedin.com/company/barebones-fitness/";

    const goToExternalLink = (externalLink: string) => {
        window.location.href = externalLink;
    }

    return (
        <div className={styles.homePageBody}>
            <Navbar homeRef={homeRef} featuresRef={featureRef} communityRef={communityRef} aboutUsRef={aboutUsRef} />
            <ParallaxProvider>
                <div className={styles.homeBody} ref={homeRef}>

                    {/* Home Section */}
                    <Parallax translateY={[-40, 40]}>
                        <div className={styles.homeSection1} >
                            <div className={styles.bigHeading}>
                                <p> Fitness<span className={styles.pinkText}>.</span> </p>
                                <p> Without<span className={styles.greenText}>.</span> </p>
                                <p> Distractions. </p>
                                <p className={styles.subHeading}> Quality fitness content. By <span className={styles.pinkText}>you</span>, for <span className={styles.greenText}>you</span>.</p>
                            </div>
                        </div>
                    </Parallax>

                    {/* Features section */}
                    <div className={styles.homeSection2} ref={featureRef}>
                        <div className={styles.featuresHeading}>
                            What <span className={styles.greenText}>we</span> offer
                        </div>
                        <div className={styles.featuresBody}>

                            <div className={styles.featureIconContainer}>
                                <div className={styles.feature1}>
                                    <img src={f1img} className={styles.featureIcon} />
                                </div>
                            </div>
                            <div className={styles.featureIconContainer}>
                                <div className={styles.feature2}>
                                    <img src={f2img} className={styles.featureIcon} />
                                </div>
                            </div>
                            <div className={styles.featureIconContainer}>
                                <div className={styles.feature1}>
                                    <img src={f3img} className={styles.featureIcon} />
                                </div>
                            </div>
                            <div className={styles.featureIconContainerEnd}>
                                <div className={styles.feature2}>
                                    <img src={f4img} className={styles.featureIcon} />
                                </div>
                            </div>

                            <p className={styles.featureHead}>
                                Social Motivation to Fitness
                            </p>
                            <p className={styles.featureHead}>
                                Customized Workouts
                            </p>
                            <p className={styles.featureHead}>
                                Trusted fitness content
                            </p>
                            <p className={styles.featureHeadEnd}>
                                Powered by AI
                            </p>

                            <p className={styles.featureBody}>
                                Connect, share, and grow together in your fitness journey with Barebones.
                            </p>
                            <p className={styles.featureBody}>
                                Tailor your workout routines with complete freedom. Beginner or pro, we help your goals become reality.
                            </p>
                            <p className={styles.featureBody}>
                                Fitness enthusiasts subscribe to verified fitness professionals for exclusive content, unique workout plans and expert advice.
                            </p>
                            <p className={styles.featureBodyEnd}>
                                Our smart LLM-powered engine will learn your habits and suggest workout plans to help you achieve your goals!
                            </p>
                        </div>
                    </div>

                    {/* Community section */}
                    <div className={styles.homeSection3} ref={communityRef}>
                        <Parallax translateY={[-10, 20]}>
                            <div className={styles.communityHeading}>
                                Join our network of <span className={styles.pinkText}>professionals</span>
                            </div>
                            <div className={styles.communitySubHeading}>
                                We allow individuals to monetize their premium content. Subscribers will have exclusive access to workouts, meal plans, Q/A, & more. Apply now and create your own community!
                            </div>
                            <div className={styles.communityBody}>
                                <div className={styles.creatorSteps}>
                                    <div className={styles.creatorStepContainer}>
                                        <div className={styles.creatorStepNumberWhite}>1</div>
                                        <div className={styles.creatorStepContent}>Create a <span className={styles.pinkText}>Barebones</span> account</div>
                                    </div>
                                    <div className={styles.creatorStepContainer}>
                                        <div className={styles.creatorStepNumberWhite}>2</div>
                                        <div className={styles.creatorStepContent}>Apply to become a Barebones <span className={styles.greenText}>athlete</span></div>
                                    </div>
                                    <div className={styles.creatorStepContainer}>
                                        <div className={styles.creatorStepNumberWhite}>3</div>
                                        <div className={styles.creatorStepContent}><span className={styles.pinkText}>Submit</span> your credentials</div>
                                    </div>
                                    <div className={styles.creatorStepContainer}>
                                        <div className={styles.creatorStepNumberWhite}>4</div>
                                        <div className={styles.creatorStepContent}>Get <span className={styles.greenText}>verified</span>!</div>
                                    </div>
                                </div>
                                <img
                                    className={styles.screenImage}
                                    src={creatorScreen}
                                />
                            </div>
                        </Parallax>
                    </div>

                    {/* About Us section */}
                    <div className={styles.homeSection4} ref={aboutUsRef}>
                        <div className={styles.aboutUsHeading}>
                            <span className={styles.greenText}>Our </span>story
                        </div>
                        <div className={styles.aboutUsSubHeading}>
                            Barebones was born out of a passion for fitness and a vision to connect individuals on their health journeys. We realized the potential for an interactive platform that combines the power of social media with the practicality of a fitness app. Our aim was to build a community that promotes shared learning, growth, and a love for fitness.
                        </div>
                        <div className={styles.aboutUsSubHeading}>
                            Meet the <span className={styles.pinkText}>team</span>:
                        </div>
                        <div className={styles.teamMembersContainer}>
                            <div className={styles.teamMember}>
                                <img src={tm1img} className={styles.teamMemberPic}></img>
                                <div className={styles.teamMemberName}>
                                    Chloe Gaynor
                                </div>
                                <div className={styles.teamMemberTitle}>
                                    Founder and CEO
                                </div>
                            </div>
                            <div className={styles.teamMember}>
                                <img src={tm2img} className={styles.teamMemberPic}></img>
                                <div className={styles.teamMemberName}>
                                    Erika Ramirez
                                </div>
                                <div className={styles.teamMemberTitle}>
                                    Head of Marketing
                                </div>
                            </div>
                            <div className={styles.teamMember}>
                                <img src={tm3img} className={styles.teamMemberPic}></img>
                                <div className={styles.teamMemberName}>
                                    Grace Hall
                                </div>
                                <div className={styles.teamMemberTitle}>
                                    Head of Operations
                                </div>
                            </div>S
                        </div>
                    </div>

                    {/* Footer section */}
                    <div className={styles.footer}>
                        <div className={styles.footerContactUs}>
                            <p>Contact us: <a href="mailto: chloe@barebonefitness.com" className={styles.footerContactUsEmail}>chloe@barebonefitness.com</a></p>
                        </div>
                        <div className={styles.footerSocialMediaHandles}>
                            <img className={styles.socialMediaIcon} src={tiktokimg} onClick={() => {
                                goToExternalLink(tiktokLink);
                            }}></img>
                            <img className={styles.socialMediaIcon} src={instaimg} onClick={() => {
                                goToExternalLink(instagramLink);
                            }}></img>
                            <img className={styles.socialMediaIcon} src={linkedinimg} onClick={() => {
                                goToExternalLink(linkedinLink);
                            }}></img>
                        </div>
                    </div>
                </div>
            </ParallaxProvider >
        </div >
    );

}

export default HomePage;